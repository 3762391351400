import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"

import OtherCaseStudy from "../../components/other-case"

import sedef from "../../assets/img/teams/19.jpg"
import melisa from "../../assets/img/teams/18.jpg"
import seher from "../../assets/img/teams/04.jpg"
import gulse from "../../assets/img/teams/48.jpg"

import mockup from "../../assets/img/kpmobil-mockup-new.png"


const ReferancesDetail = () => (
    <Layout>
        <SEO title="Kentpark Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-5 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1 style={{ margin: '20px auto 20px' }}>Kentpark AVM</h1>
                <p style={{ maxWidth: 420}}>Ankara'nın seçkin alışveriş merkezlerinden biri olan Kentpark Alışveriş Merkezi için Kadınlar Günü'ne özel bir proje geliştirme fikriyle yola çıktık. Burada amacımız marka bilinirliği yüksek olan alışveriş merkezimizin takipçilerine Kadınlar Günü'nde farklı bir deneyim yaşatmak oldu.</p>
               
                
                </div>
            <div className="col-md-7 referances-boxs">
                <div className="provided">
                    <h3 style={{ margin: '10px 0' }} >Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/sosyal-medya/"></Link>
                            <svg id="instagram-logo" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 61.312 61.312">
                                <path id="Path_36346" data-name="Path 36346" d="M44.392,0H16.92A16.939,16.939,0,0,0,0,16.92V44.392a16.939,16.939,0,0,0,16.92,16.92H44.392a16.939,16.939,0,0,0,16.92-16.92V16.92A16.939,16.939,0,0,0,44.392,0ZM55.872,44.392a11.493,11.493,0,0,1-11.48,11.48H16.92A11.493,11.493,0,0,1,5.44,44.392V16.92A11.493,11.493,0,0,1,16.92,5.44H44.392a11.493,11.493,0,0,1,11.48,11.48V44.392Z" />
                                <path id="Path_36347" data-name="Path 36347" d="M56.767,40.97a15.8,15.8,0,1,0,15.8,15.8A15.816,15.816,0,0,0,56.767,40.97Zm0,26.156A10.358,10.358,0,1,1,67.125,56.768,10.37,10.37,0,0,1,56.767,67.126Z" transform="translate(-26.111 -26.112)" />
                                <path id="Path_36348" data-name="Path 36348" d="M122.91,28.251a3.987,3.987,0,1,0,2.821,1.168A4,4,0,0,0,122.91,28.251Z" transform="translate(-75.793 -18.005)" />
                            </svg>
                            Sosyal Medya
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3 style={{ margin: '1px 0' }}>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Stratejisti:</li>
                            <li>Proje Sorumlusu:</li>
                            <li>Proje Sorumlusu:</li>
                            <li>Digital Tasarımcı:</li>
                        </ul>
                        <ul>
                            <li><img src={melisa} />Melisa Akınsal</li>
                            <li><img src={seher} />Seher Taylan</li>
                            <li><img src={sedef} />Sedef Çelik</li>
                            <li><img src={gulse}  />Gülse Erdek</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div className="container">

            <div className="content-section content row" >
                <div className="col-md-6">
                    <img className="pr100" src={mockup} />
                </div>
                <div className="col-md-6">
                    <p className="small" style={{color:"#ea4f6a" }} >Başarı Hikayesi</p>
                    <p style={{ maxWidth: 429 }} >Bu amaç doğrultusunda sayfamıza özel bir AR filtre oluşturarak "Sen Hangi Güçlü Kadınsın?" diye sorduk. Filtreyi kullanan takipçilerimizden sonuçlarını sayfamızı etiketleyerek paylaşmalarını istedik.</p>
                </div>
            </div>
            <div style={{ margin: '50px auto 50px' }} className="text-center">
                <h2  style={{color:"#ea4f6a" }} >Sen Hangi Güçlü Kadınsın? </h2>
                <p  style={{color:"#ea4f6a", fontSize: 18}} >Başarı Hikayesi</p>
                <p style={{ fontSize: 18, margin: '7px auto 10px', maxWidth: 800 }} >Projemiz ile 3 gün içerisinde +100.000 kişiyi markamız ile buluşturduk. "Sen Hangi Güçlü Kadınsın?" temalı AR filtremiz 18.963 kez görüntülendi. Tarihteki hangi güçlü kadına benzediğini görmek isteyen 2.232 kişi filtremizi denedi.</p>
            </div>
            <div className="referances-detail container" > 
            <div className="col-md-1">
            </div>
            <div className="col-md-10 referances-boxs">
                <div className="boxs" style={{ marginBottom: '0' }}>
                    <div className="box" style={{ width:220}} >
                        <span>+100k</span>
                        <p>Kişi Markamızla Buluştu</p>
                    </div>
                    <div className="box" style={{ width:220}}>
                        <span>18.963</span>
                        <p>Görüntülenme Sayısı</p>
                    </div>
                    <div className="box" style={{ width:220}}>
                        <span>2.323</span>
                        <p>Kişi Filtremizi Denedi</p>
                    </div>
                </div>
            </div>
            <div className="col-md-1">   
            </div>
        </div>
        <div style={{ margin: '50px auto 50px' }} className="text-center">
                <p  style={{color:"#ea4f6a", fontSize: 18}} >Başarı Hikayesi</p>
                <p style={{ fontSize: 18, margin: '7px auto 10px', maxWidth: 800 }} > Bu sayede Kadınlar Günü'nde tarihimizdeki önemli kadın isimleri takipçilerimizle eşleştirerek güzel bir deneyim sağlamalarını başardık. Aynı zamanda henüz sayfamızı takip etmeyen kişilere ulaşarak yeni takipçiler kazandık.</p>
            </div>
        </div>
        
           
        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
